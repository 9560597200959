import { lazy } from 'react'

import { Checkbox, List, Select, Shape, Slot, Style, TextInput } from '@makeswift/runtime/controls'

import { runtime } from '@/lib/makeswift/runtime'

runtime.registerComponent(
	lazy(() => import('./PillTabs').then((module) => ({ default: module.PillTabs }))),
	{
		type: 'PillTabs',
		label: `Pill Tabs`,
		props: {
			className: Style(),
			pills: List({
				label: 'Pill',
				type: Shape({
					type: {
						title: TextInput({
							label: 'Pill title',
							defaultValue: 'Pill title',
							selectAll: true
						}),
						content: Slot()
					}
				}),
				getItemLabel(pill) {
					return pill?.title || 'Pill title'
				}
			}),
			containerAlignment: Select({
				label: 'Container alignment',
				options: [
					{ value: 'left', label: 'Left' },
					{ value: 'center', label: 'Center' },
					{ value: 'right', label: 'Right' }
				],
				defaultValue: 'left'
			}),
			theme: Select({
				label: 'Theme',
				options: [
					{
						value: 'default',
						label: 'Default'
					},
					{
						value: 'defaultInverse',
						label: 'Default Inverse'
					},
					{
						value: 'ghost',
						label: 'Ghost'
					},
					{
						value: 'ghostInverse',
						label: 'Ghost Inverse'
					}
				],
				defaultValue: 'default'
			}),
			showFade: Checkbox({ label: 'Overflow Fade', defaultValue: false }),
			fadeTheme: Select({
				label: 'Fade Color',
				options: [
					{
						label: 'BC Black',
						value: '#121118'
					},
					{
						label: 'True Black',
						value: '#000'
					},
					{
						label: 'Gray 200',
						value: '#EDEFF3'
					},
					{
						label: 'Gray 100',
						value: '#F6F7F9'
					},
					{
						label: 'True White',
						value: '#FFF'
					}
				],
				defaultValue: '#121118'
			})
		}
	}
)
